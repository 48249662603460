import React from "react";
import VideoBackground from "../components/VideoBackground";
import bgVid from "../assets/Gameplanet-bg.mp4";
import shardGate from "../assets/shardgate.png";
import logo from "../assets/logo.png";
import twitter from "../assets/twitter.png";
import telegram from "../assets/telegram.png";
import medium from "../assets/medium.png";

const TeaserPage = () => {

  return (
    <>
      <div className="flex flex-col">
        <div className="w-full relative video-container h-screen lg:h-full">
          <VideoBackground src={bgVid} autoPlay loop muted />
          <div className="absolute left-0 top-0 w-full flex flex-col items-center justify-start z-10 pt-12">
            <div className="text-center flex flex-col items-center justify-center w-full lg:w-auto px-4">
              <img className="w-1/2" src={logo} alt="Gameplanet.ai" />
              <span className="text-3xl lg:text-6xl text-white font-montserrat-text font-bold mb-4 tracking-wide">
                GAMEPLANET.AI
              </span>
              <p className="text-xl lg:text-2xl text-white font-montserrat-text font-extralight tracking-wide">
                Welcome to a world where anyone can create, incubate and grow
                <br></br>
                the next generation of video games
              </p>
            </div>
            <div className="mt-28 flex flex-col items-center">
              <span className="text-2xl text-white font-montserrat-text font-extralight tracking-wide">Follow our socials for more info</span>
              <ul className="flex justify-center items-center mt-8 w-3/4 lg:w-auto">
                <li className="flex justify-center items-center"><a className="w-auto flex justify-center" href="https://x.com/gameplanetai"><img className="w-1/2" src={twitter} alt="twitter" /></a></li>
                <li className="flex justify-center items-center"><a className="w-auto flex justify-center" href="https://t.me/gameplanetai"><img className="w-1/2" src={telegram} alt="telegram" /></a></li>
                <li className="flex justify-center items-center"><a className="w-auto flex justify-center" href="https://medium.com/gameplanetai"><img className="w-1/2" src={medium} alt="medium" /></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bg-black w-full py-12 px-4">
          <div className="flex flex-col items-center justify-center">
            <span className="text-white font-montserrat-text font-light text-2xl mb-4 tracking-wide">
              Planet Generation Event happening soon!
            </span>
            <a
              className="w-1/2 flex justify-center items-center"
              href="https://shardgate.ai/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-full lg:w-1/3" src={shardGate} alt="Shardgate" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeaserPage;
