import React, { useRef, useEffect, useState } from 'react';

const VideoBackground = ({ src, autoPlay = true, loop = true, muted = true }) => {
  const videoRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleCanPlay = () => {
      setIsReady(true);
    };

    const handleEnded = () => {
      videoElement.currentTime = 0;
      if (isPlaying) {
        videoElement.play();
      }
    };

    if (videoElement) {
      videoElement.addEventListener('canplay', handleCanPlay);
      videoElement.addEventListener('ended', handleEnded);
      setIsPlaying(autoPlay); // Set initial playing state
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('canplay', handleCanPlay);
        videoElement.removeEventListener('ended', handleEnded);
      }
    };
  }, [autoPlay, isPlaying]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && isReady) {
      if (isPlaying) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    }
  }, [isPlaying, isReady]);

  return (
    <div className="video-background relative">
      <video
        ref={videoRef}
        autoPlay={false}
        loop={loop}
        muted={muted}
        playsInline
        onCanPlay={() => setIsReady(true)} // Set video as ready when it can play
        style={{
          width: '100%',
          objectFit: 'cover',
          zIndex: -1,
          opacity: isReady && isPlaying ? '1' : '0', // Show video only when ready and playing
          transition: 'opacity 0.5s ease-in-out', // Adjust transition as needed
        }}
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoBackground;
